import Vue from 'vue'

import {BAlert} from "bootstrap-vue";
import {BBadge} from "bootstrap-vue";
import {BBreadcrumb} from "bootstrap-vue";
import {BBreadcrumbItem} from "bootstrap-vue";
import {BBreadcrumbLink} from "bootstrap-vue";
import {BButton} from "bootstrap-vue";
import {BButtonClose} from "bootstrap-vue";
import {BButtonGroup} from "bootstrap-vue";
import {BButtonToolbar} from "bootstrap-vue";
import {BCard} from "bootstrap-vue";
import {BCardBody} from "bootstrap-vue";
import {BCardFooter} from "bootstrap-vue";
import {BCardGroup} from "bootstrap-vue";
import {BCardHeader} from "bootstrap-vue";
import {BCardImg} from "bootstrap-vue";
import {BCarousel} from "bootstrap-vue";
import {BCarouselSlide} from "bootstrap-vue";
import {BCol} from "bootstrap-vue";
import {BCollapse} from "bootstrap-vue";
import {BContainer} from "bootstrap-vue";
import {BDropdown} from "bootstrap-vue";
import {BDropdownDivider} from "bootstrap-vue";
import {BDropdownHeader} from "bootstrap-vue";
import {BDropdownItem} from "bootstrap-vue";
import {BDropdownItemButton} from "bootstrap-vue";
import {BEmbed} from "bootstrap-vue";
import {BForm} from "bootstrap-vue";
import {BFormCheckbox} from "bootstrap-vue";
import {BFormCheckboxGroup} from "bootstrap-vue";
import {BFormFile} from "bootstrap-vue";
import {BFormGroup} from "bootstrap-vue";
import {BFormInput} from "bootstrap-vue";
import {BFormInvalidFeedback} from "bootstrap-vue";
import {BFormRadio} from "bootstrap-vue";
import {BFormRadioGroup} from "bootstrap-vue";
import {BFormRow} from "bootstrap-vue";
import {BFormSelect} from "bootstrap-vue";
import {BFormText} from "bootstrap-vue";
import {BFormTextarea} from "bootstrap-vue";
import {BFormValidFeedback} from "bootstrap-vue";
import {BImg} from "bootstrap-vue";
import {BImgLazy} from "bootstrap-vue";
import {BInputGroup} from "bootstrap-vue";
import {BInputGroupAddon} from "bootstrap-vue";
import {BInputGroupAppend} from "bootstrap-vue";
import {BInputGroupPrepend} from "bootstrap-vue";
import {BInputGroupText} from "bootstrap-vue";
import {BJumbotron} from "bootstrap-vue";
import {BLink} from "bootstrap-vue";
import {BListGroup} from "bootstrap-vue";
import {BListGroupItem} from "bootstrap-vue";
import {BMedia} from "bootstrap-vue";
import {BMediaAside} from "bootstrap-vue";
import {BMediaBody} from "bootstrap-vue";
import {BModal} from "bootstrap-vue";
import {BNav} from "bootstrap-vue";
import {BNavbar} from "bootstrap-vue";
import {BNavbarBrand} from "bootstrap-vue";
import {BNavbarNav} from "bootstrap-vue";
import {BNavbarToggle} from "bootstrap-vue";
import {BNavForm} from "bootstrap-vue";
import {BNavItem} from "bootstrap-vue";
import {BNavItemDropdown} from "bootstrap-vue";
import {BNavText} from "bootstrap-vue";
import {BPagination} from "bootstrap-vue";
import {BPaginationNav} from "bootstrap-vue";
import {BPopover} from "bootstrap-vue";
import {BProgress} from "bootstrap-vue";
import {BProgressBar} from "bootstrap-vue";
import {BRow} from "bootstrap-vue";
import {BTab} from "bootstrap-vue";
import {BTable} from "bootstrap-vue";
import {BTabs} from "bootstrap-vue";
import {BTooltip} from "bootstrap-vue";

// import bAlert from 'bootstrap-vue/es/components/alert/alert'
// import bBadge from 'bootstrap-vue/es/components/badge/badge'
// import bBreadcrumb from 'bootstrap-vue/es/components/breadcrumb/breadcrumb'
// import bBreadcrumbItem from 'bootstrap-vue/es/components/breadcrumb/breadcrumb-item'
// import bBreadcrumbLink from 'bootstrap-vue/es/components/breadcrumb/breadcrumb-link'
// import bButton from 'bootstrap-vue/es/components/button/button'
// import bButtonClose from 'bootstrap-vue/es/components/button/button-close'
// import bButtonGroup from 'bootstrap-vue/es/components/button-group/button-group'
// import bButtonToolbar from 'bootstrap-vue/es/components/button-toolbar/button-toolbar'
// import bCard from 'bootstrap-vue/es/components/card/card'
// import bCardBody from 'bootstrap-vue/es/components/card/card-body'
// import bCardFooter from 'bootstrap-vue/es/components/card/card-footer'
// import bCardGroup from 'bootstrap-vue/es/components/card/card-group'
// import bCardHeader from 'bootstrap-vue/es/components/card/card-header'
// import bCardImage from 'bootstrap-vue/es/components/card/card-img'
// import bCarousel from 'bootstrap-vue/es/components/carousel/carousel'
// import bCarouselSlide from 'bootstrap-vue/es/components/carousel/carousel-slide'
// import bCol from 'bootstrap-vue/es/components/layout/col'
// import bCollapse from 'bootstrap-vue/es/components/collapse/collapse'
// import bContainer from 'bootstrap-vue/es/components/layout/container'
// import bDropdown from 'bootstrap-vue/es/components/dropdown/dropdown'
// import bDropdownDivider from 'bootstrap-vue/es/components/dropdown/dropdown-divider'
// import bDropdownHeader from 'bootstrap-vue/es/components/dropdown/dropdown-header'
// import bDropdownItem from 'bootstrap-vue/es/components/dropdown/dropdown-item'
// import bDropdownItemButton from 'bootstrap-vue/es/components/dropdown/dropdown-item-button'
// import bEmbed from 'bootstrap-vue/es/components/embed/embed'
// import bForm from 'bootstrap-vue/es/components/form/form'
// import bFormCheckbox from 'bootstrap-vue/es/components/form-checkbox/form-checkbox'
// import bFormCheckboxGroup from 'bootstrap-vue/es/components/form-checkbox/form-checkbox-group'
// import bFormFile from 'bootstrap-vue/es/components/form-file/form-file'
// import bFormGroup from 'bootstrap-vue/es/components/form-group/form-group'
// import bFormInput from 'bootstrap-vue/es/components/form-input/form-input'
// import bFormInvalidFeedback from 'bootstrap-vue/es/components/form/form-invalid-feedback'
// import bFormRadio from 'bootstrap-vue/es/components/form-radio/form-radio'
// import bFormRadioGroup from 'bootstrap-vue/es/components/form-radio/form-radio-group'
// import bFormRow from 'bootstrap-vue/es/components/layout/form-row'
// import bFormSelect from 'bootstrap-vue/es/components/form-select/form-select'
// import bFormText from 'bootstrap-vue/es/components/form/form-text'
// import bFormTextarea from 'bootstrap-vue/es/components/form-textarea/form-textarea'
// import bFormValidFeedback from 'bootstrap-vue/es/components/form/form-valid-feedback'
// import bImage from 'bootstrap-vue/es/components/image/img'
// import bImageLazy from 'bootstrap-vue/es/components/image/img-lazy'
// import bInputGroup from 'bootstrap-vue/es/components/input-group/input-group'
// import bInputGroupAddon from 'bootstrap-vue/es/components/input-group/input-group-addon'
// import bInputGroupAppend from 'bootstrap-vue/es/components/input-group/input-group-append'
// import bInputGroupPrepend from 'bootstrap-vue/es/components/input-group/input-group-prepend'
// import bInputGroupText from 'bootstrap-vue/es/components/input-group/input-group-text'
// import bJumbotron from 'bootstrap-vue/es/components/jumbotron/jumbotron'
// import bLink from 'bootstrap-vue/es/components/link/link'
// import bListGroup from 'bootstrap-vue/es/components/list-group/list-group'
// import bListGroupItem from 'bootstrap-vue/es/components/list-group/list-group-item'
// import bMedia from 'bootstrap-vue/es/components/media/media'
// import bMediaAside from 'bootstrap-vue/es/components/media/media-aside'
// import bMediaBody from 'bootstrap-vue/es/components/media/media-body'
// import bModal from 'bootstrap-vue/es/components/modal/modal'
// import bNav from 'bootstrap-vue/es/components/nav/nav'
// import bNavbar from 'bootstrap-vue/es/components/navbar/navbar'
// import bNavbarBrand from 'bootstrap-vue/es/components/navbar/navbar-brand'
// import bNavbarNav from 'bootstrap-vue/es/components/navbar/navbar-nav'
// import bNavbarToggle from 'bootstrap-vue/es/components/navbar/navbar-toggle'
// import bNavForm from 'bootstrap-vue/es/components/nav/nav-form'
// import bNavItem from 'bootstrap-vue/es/components/nav/nav-item'
// import bNavItemDropdown from 'bootstrap-vue/es/components/nav/nav-item-dropdown'
// import bNavText from 'bootstrap-vue/es/components/nav/nav-text'
// import bPagination from 'bootstrap-vue/es/components/pagination/pagination'
// import bPaginationNav from 'bootstrap-vue/es/components/pagination-nav/pagination-nav'
// import bPopover from 'bootstrap-vue/es/components/popover/popover'
// import bProgress from 'bootstrap-vue/es/components/progress/progress'
// import bProgressBar from 'bootstrap-vue/es/components/progress/progress-bar'
// import bRow from 'bootstrap-vue/es/components/layout/row'
// import bTab from 'bootstrap-vue/es/components/tabs/tab'
// import bTable from 'bootstrap-vue/es/components/table/table'
// import bTabs from 'bootstrap-vue/es/components/tabs/tabs'
// import bTooltip from 'bootstrap-vue/es/components/tooltip/tooltip'


Vue.component('b-alert', BAlert)
Vue.component('b-badge', BBadge)
Vue.component('b-breadcrumb', BBreadcrumb)
Vue.component('b-breadcrumb-item', BBreadcrumbItem)
Vue.component('b-breadcrumb-link', BBreadcrumbLink)
Vue.component('b-button', BButton)
Vue.component('b-button-close',BButtonClose)
Vue.component('b-button-group', BButtonGroup)
Vue.component('b-button-toolbar', BButtonToolbar)
Vue.component('b-card', BCard)
Vue.component('b-card-body', BCardBody)
Vue.component('b-card-footer', BCardFooter)
Vue.component('b-card-group', BCardGroup)
Vue.component('b-card-header', BCardHeader)
Vue.component('b-card-image', BCardImg)
Vue.component('b-carousel', BCarousel)
Vue.component('b-carousel-slide', BCarouselSlide)
Vue.component('b-col', BCol)
Vue.component('b-collapse', BCollapse)
Vue.component('b-container', BContainer)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-divider', BDropdownDivider)
Vue.component('b-dropdown-header', BDropdownHeader)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-dropdown-item-button', BDropdownItemButton)
Vue.component('b-embed', BEmbed)
Vue.component('b-form', BForm)
Vue.component('b-form-checkbox', BFormCheckbox)
Vue.component('b-form-checkbox-group', BFormCheckboxGroup)
Vue.component('b-form-file', BFormFile)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-input', BFormInput)
Vue.component('b-form-invalid-feedback', BFormInvalidFeedback)
Vue.component('b-form-radio', BFormRadio)
Vue.component('b-form-radio-group', BFormRadioGroup)
Vue.component('b-form-row', BFormRow)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-form-text', BFormText)
Vue.component('b-form-textarea', BFormTextarea)
Vue.component('b-form-valid-feedback', BFormValidFeedback)
Vue.component('b-image', BImg)
Vue.component('b-image-lazy', BImgLazy)
Vue.component('b-input-group', BInputGroup)
Vue.component('b-input-group-addon', BInputGroupAddon)
Vue.component('b-input-group-append', BInputGroupAppend)
Vue.component('b-input-groupd-prepend', BInputGroupPrepend)
Vue.component('b-input-group-text', BInputGroupText)
Vue.component('b-jumbotron', BJumbotron)
Vue.component('b-link', BLink)
Vue.component('b-list-group', BListGroup)
Vue.component('b-list-group-item', BListGroupItem)
Vue.component('b-media', BMedia)
Vue.component('b-media-aside', BMediaAside)
Vue.component('b-media-body', BMediaBody)
Vue.component('b-modal', BModal)
Vue.component('b-nav', BNav)
Vue.component('b-nav-form', BNavForm)
Vue.component('b-nav-item', BNavItem)
Vue.component('b-nav-item-dropdown', BNavItemDropdown)
Vue.component('b-nav-text', BNavText)
Vue.component('b-navbar', BNavbar)
Vue.component('b-navbar-brand', BNavbarBrand)
Vue.component('b-navbar-nav', BNavbarNav)
Vue.component('b-navbar-toggle', BNavbarToggle)
Vue.component('b-pagination', BPagination)
Vue.component('b-pagination-nav', BPaginationNav)
Vue.component('b-popover', BPopover)
Vue.component('b-progress', BProgress)
Vue.component('b-progress-bar', BProgressBar)
Vue.component('b-row', BRow)
Vue.component('b-tab', BTab)
Vue.component('b-table', BTable)
Vue.component('b-tabs', BTabs)
Vue.component('b-tooltip', BTooltip)

// Vue.component('b-alert', bAlert)
// Vue.component('b-badge', bBadge)
// Vue.component('b-breadcrumb', bBreadcrumb)
// Vue.component('b-breadcrumb-item', bBreadcrumbItem)
// Vue.component('b-breadcrumb-link', bBreadcrumbLink)
// Vue.component('b-button', bButton)
// Vue.component('b-button-close', bButtonClose)
// Vue.component('b-button-group', bButtonGroup)
// Vue.component('b-button-toolbar', bButtonToolbar)
// Vue.component('b-card', bCard)
// Vue.component('b-card-body', bCardBody)
// Vue.component('b-card-footer', bCardFooter)
// Vue.component('b-card-group', bCardGroup)
// Vue.component('b-card-header', bCardHeader)
// Vue.component('b-card-image', bCardImage)
// Vue.component('b-carousel', bCarousel)
// Vue.component('b-carousel-slide', bCarouselSlide)
// Vue.component('b-col', bCol)
// Vue.component('b-collapse', bCollapse)
// Vue.component('b-container', bContainer)
// Vue.component('b-dropdown', bDropdown)
// Vue.component('b-dropdown-divider', bDropdownDivider)
// Vue.component('b-dropdown-header', bDropdownHeader)
// Vue.component('b-dropdown-item', bDropdownItem)
// Vue.component('b-dropdown-item-button', bDropdownItemButton)
// Vue.component('b-embed', bEmbed)
// Vue.component('b-form', bForm)
// Vue.component('b-form-checkbox', bFormCheckbox)
// Vue.component('b-form-checkbox-group', bFormCheckboxGroup)
// Vue.component('b-form-file', bFormFile)
// Vue.component('b-form-group', bFormGroup)
// Vue.component('b-form-input', bFormInput)
// Vue.component('b-form-invalid-feedback', bFormInvalidFeedback)
// Vue.component('b-form-radio', bFormRadio)
// Vue.component('b-form-radio-group', bFormRadioGroup)
// Vue.component('b-form-row', bFormRow)
// Vue.component('b-form-row', bFormRow)
// Vue.component('b-form-select', bFormSelect)
// Vue.component('b-form-text', bFormText)
// Vue.component('b-form-textarea', bFormTextarea)
// Vue.component('b-form-valid-feedback', bFormValidFeedback)
// Vue.component('b-image', bImage)
// Vue.component('b-image-lazy', bImageLazy)
// Vue.component('b-input-group', bInputGroup)
// Vue.component('b-input-group-addon', bInputGroupAddon)
// Vue.component('b-input-group-append', bInputGroupAppend)
// Vue.component('b-input-group-prepend', bInputGroupPrepend)
// Vue.component('b-input-group-text', bInputGroupText)
// Vue.component('b-jumbotron', bJumbotron)
// Vue.component('b-link', bLink)
// Vue.component('b-list-group', bListGroup)
// Vue.component('b-list-group-item', bListGroupItem)
// Vue.component('b-media', bMedia)
// Vue.component('b-media-aside', bMediaAside)
// Vue.component('b-media-body', bMediaBody)
// Vue.component('b-modal', bModal)
// Vue.component('b-nav', bNav)
// Vue.component('b-nav-form', bNavForm)
// Vue.component('b-nav-item', bNavItem)
// Vue.component('b-nav-item-dropdown', bNavItemDropdown)
// Vue.component('b-nav-text', bNavText)
// Vue.component('b-navbar', bNavbar)
// Vue.component('b-navbar-brand', bNavbarBrand)
// Vue.component('b-navbar-nav', bNavbarNav)
// Vue.component('b-navbar-toggle', bNavbarToggle)
// Vue.component('b-pagination', bPagination)
// Vue.component('b-pagination-nav', bPaginationNav)
// Vue.component('b-popover', bPopover)
// Vue.component('b-progress', bProgress)
// Vue.component('b-progress-bar', bProgressBar)
// Vue.component('b-row', bRow)
// Vue.component('b-tab', bTab)
// Vue.component('b-table', bTable)
// Vue.component('b-tabs', bTabs)
// Vue.component('b-tooltip', bTooltip)