<template>
    <div>
        <div class="main" id="main" style="position: relative">
            <transition name="slide-fade" style="z-index: -100;">
                <!-- 			SRC comes from the array of images the :key is important for vue to believe its a 'new' DOM element and do the transition -->
                <img v-bind:src="images[currentID]" v-bind:key="currentID" />
            </transition>

            <div class="landing-background" style="position: relative;">
                <div class="green-skew" style="z-index: 100;"></div>
                <b-container fluid class="inner-container">
                    <b-row>
                        <b-col sm="10" md="8" lg="6" xl="5">
                            <h1 class="m-auto">{{ $t('landing.header')}}</h1>
                            <h6 class="m-auto pt-5 pr-5">{{ $t('about_us.about_us_text')}}</h6>
                            <div class="py-5" @click="$router.push({name: 'Services'})" style="z-index: 200; position: relative;">
                                <h4 class="d-inline-flex more-button mx-auto" style="cursor: pointer;">{{ $t('button.landing')}}</h4>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
                <div class="zonti-element"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Landing",
        data() {
            return {
                currentID: 0,
                images: [
                    'https://zonti-bucket.s3.amazonaws.com/assets/images/DSC01166-min.JPG',
                    'https://zonti-bucket.s3.amazonaws.com/assets/images/compressed_images/DSC01201-min.jpg',
                    'https://zonti-bucket.s3.amazonaws.com/assets/images/compressed_images/DSC01058-min.JPG',
                    'https://zonti-bucket.s3.amazonaws.com/assets/images/compressed_images/DSC01082-min.JPG'
                ]
            }
        },
        methods: {
            toggleImage() {
                if (this.currentID < this.images.length-1) {
                    this.currentID += 1
                } else {
                    this.currentID = 0
                }
            }
        },
        created() {
            setTimeout(() => this.toggleImage(), 10000);
        },
        updated() {
            setTimeout(() => this.toggleImage(), 10000);
        }
    }
</script>

<style scoped>
    .landing-background {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        min-height: 90vh;
        height: 100%;
        width: 100%;
        position: absolute;
    }
    .green-skew {
        background-color: #034D4D;
        opacity: 0.96;
        min-height: 90vh;
        height: 100%;
        width: 100%;
        -webkit-clip-path: polygon(0 0, 60% 0, 45% 100%, 0% 100%);
        clip-path: polygon(0 0, 60% 0, 45% 100%, 0% 100%);
        backdrop-filter: blur(4px);
        position: absolute;
        top: 0;
        left: 0;
    }
    h1, h4, h6, p {
        color: #FFF;
        position: relative;
        z-index: 100;
    }
    h6 {
        line-height: 1.5;
    }
    h1, h4 {
        font-weight: 700;
    }
    .more-button::after {
        content: " ";
        display: block;
        background: #058B8C;
        opacity: 0.4;
        height: 26px;
        width: 105%;
        position: absolute;
        z-index: -100;
        top: 15px;
        left: -5px;
    }
    .zonti-element {
        opacity: 0.4;
        position: absolute;
        z-index: 100;
        bottom: 0;
        left: 0;
        background-image: url('https://zonti-bucket.s3.amazonaws.com/assets/zonti_icon.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        height: 200px;
        width: 200px;
        overflow: hidden;
    }
    .inner-container {
        padding-top: 200px;
        padding-left: 100px;
        margin-top: -6px;
    }

    body {
        overflow: hidden;
    }

    .main {
        position: relative;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        position: absolute;
        -webkit-transition: all 3s ease;
        transition: all 3s ease;
    }

    button {
        z-index: 100;
        position: relative;
        width: 200px;
        height: 25px;
        margin: 20px;
    }

    /* prefix with transition name */
    .slide-fade-enter-active {
        opacity: 1;
        z-index: 10;
    }

    .slide-fade-leave-active {
        opacity: 1;
    }

    .slide-fade-enter,
    .slide-fade-leave-to {
        opacity: 0;
    }

    @media only screen and (max-width: 1440px) {
        .green-skew {
            -webkit-clip-path: polygon(0 0, 70% 0, 40% 100%, 0% 100%);
            clip-path: polygon(0 0, 70% 0, 40% 100%, 0% 100%);
        }
    }

    @media only screen and (max-width: 1280px) {
        .green-skew {
            -webkit-clip-path: polygon(0 0, 70% 0, 45% 100%, 0% 100%);
            clip-path: polygon(0 0, 70% 0, 45% 100%, 0% 100%);
        }
    }

      @media only screen and (max-width: 1024px) {
        .inner-container {
            padding-top: 125px;
            padding-left: 100px;
            margin-top: -1px;
        }
    }

    @media only screen and (max-width: 991px) {
        .green-skew {
            -webkit-clip-path: polygon(0 0, 85% 0, 55% 100%, 0% 100%);
            clip-path: polygon(0 0, 85% 0, 55% 100%, 0% 100%);
        }
        .inner-container {
            padding-top: 100px;
            padding-left: 50px;
            margin-top: -1px;
        }
    }

    @media only screen and (max-width: 768px) {
        .green-skew {
            -webkit-clip-path: polygon(0 0, 100% 0, 65% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
        }
        .inner-container {
            margin-top: -2px;
        }
    }

    @media only screen and (max-width: 576px) {
        .green-skew {
            -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
        }
        h1 {
            font-size: 2.25em;
        }
    }
</style>