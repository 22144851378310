<template>
    <div class="contact-container">
        <b-container class="contact-body pt-5 p-sm-5">
            <h1 class="text-center">{{ $t('contact.contact_header')}}</h1>

            <b-row class="pt-3 pt-sm-5">
                <b-col lg="6">
                    <b-row class="pb-md-5">
                        <b-col sm="5" class="d-flex">
                            <div class="zonti-element-full mx-auto mb-3 mb-sm-0"></div>
                        </b-col>
                        <b-col sm="7" class="d-flex">
                            <div class="mx-auto">
                                <h5 class="pb-3">{{ $t('contact.contact_info_header')}}</h5>
                                <div class="d-flex">
                                    <div class="my-auto">
                                        <i class="mdi mdi-domain my-auto" style="font-size: 1.5em;"></i>
                                    </div>
                                    <div class="my-auto pl-2" style="white-space: nowrap">
                                        <div>{{ $t('contact_info.street')}}</div>
                                        <div>{{ $t('contact_info.city_zip')}}</div>
                                    </div>
                                </div>
                                <a class="d-flex py-3" href="mailto:biuro@zonti.pl">
                                    <div class="my-auto">
                                        <i class="mdi mdi-email my-auto" style="font-size: 1.5em;"></i>
                                    </div>
                                    <div class="my-auto pl-2">{{ $t('contact_info.email')}}</div>
                                </a>
                                <a class="d-flex" href="tel:+48609169500">
                                    <div class="my-auto">
                                        <i class="mdi mdi-phone my-auto" style="font-size: 1.5em;"></i>
                                    </div>
                                    <div class="my-auto pl-2">{{ $t('contact_info.phone')}}</div>
                                </a>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col lg="6" class="pt-5 pt-lg-0">
                    <div class="pb-5" style="height: 100%;">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2508.3262048506135!2d16.880462515753127!3d51.047064279562115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fc72cea381815%3A0x77b74b9cd3bc5948!2sFabryczna%2018%2C%2055-080%20Pietrzykowice!5e0!3m2!1sen!2spl!4v1621346640132!5m2!1sen!2spl" width="100%" height="450" style="border:0; border-radius: 25px;" allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <div class="zonti-element"></div>
        <div class="zonti-element-2"></div>
    </div>
</template>

<script>
    export default {
        name: "Contact"
    }
</script>

<style scoped>
    a {
        text-decoration: none;
        color: unset;
    }
    .contact-container {
        background-color: #E5E5E5;
        position: relative;
    }
    .contact-body {
        position: relative;
        z-index: 100;
    }
    .zonti-element {
        opacity: 0.4;
        position: absolute;
        z-index: 0;
        top: 25px;
        right: 25px;
        background-image: url('https://zonti-bucket.s3.amazonaws.com/assets/zonti_icon.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        height: 400px;
        width: 400px;
        overflow: hidden;
    }
    .zonti-element-2 {
        opacity: 0.3;
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: 0;
        background-image: url('https://zonti-bucket.s3.amazonaws.com/assets/zonti_icon.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        height: 300px;
        width: 300px;
        overflow: hidden;
    }
    .zonti-element-full {
        opacity: 1;
        background-image: url('https://zonti-bucket.s3.amazonaws.com/assets/ZONTI-wizytowki2021-ACha.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        height: 200px;
        width: 200px;
    }

    @media only screen and (max-width: 991px) {
        .container {
            max-width: unset;
        }
    }
</style>