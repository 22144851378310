import Vue from 'vue'
import App from './App.vue'
import router from './router'

import BootstrapVue from 'bootstrap-vue'
import './components/vue-bootstrap.js'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@mdi/js'

import i18n from './i18n'

Vue.config.productionTip = false;

Vue.use(BootstrapVue);

const vue = new Vue({
  router,
  i18n,
  render: h => h(App)
});

vue.$mount('#app');