<template>
    <div class="footer-container p-5">
        <b-container>
            <b-row>
                <b-col sm="6" lg="3" class="pb-5 d-flex justify-content-center justify-content-sm-start">
                    <div>
                        <h5 style="cursor: pointer;" @click="$router.push({name: 'Documents'})">{{ $t('navbar.documents')}}</h5>
                        <!--                        <h5 class="py-3" style="cursor: pointer;" @click="$router.push({name: 'Contact'})">{{ $t('navbar.contact')}}</h5>-->
                        <!--                        <h5 style="cursor: pointer;" @click="$router.push({name: 'About'})">{{ $t('navbar.about')}}</h5>-->
                    </div>
                </b-col>

                <b-col sm="6" lg="3" class="pb-5 d-flex justify-content-center justify-content-sm-start">
                    <div>
                        <!--                        <h5>{{ $t('footer.social_media')}}</h5>-->
                        <!--                        <div class="d-flex">-->
                        <!--                            <i class="fab fa-linkedin mx-auto" style="font-size: 2em;"></i>-->
                        <!--                        </div>-->
                        <h5 style="cursor: pointer;" @click="$router.push({name: 'Services'})">{{ $t('navbar.services')}}</h5>
                    </div>
                </b-col>

                <b-col sm="6" lg="3" class="d-flex justify-content-center justify-content-md-start">
                    <div>
                        <h5 @click="$router.push({name: 'Contact'})" style="cursor: pointer;">{{ $t('footer.contact')}}</h5>
                        <div class="d-flex">
                            <div class="my-auto">
                                <i class="mdi mdi-domain my-auto" style="font-size: 1.5em;"></i>
                            </div>
                            <div class="my-auto pl-2" style="white-space: nowrap">
                                <div>{{ $t('contact_info.street')}}</div>
                                <div>{{ $t('contact_info.city_zip')}}</div>
                            </div>
                        </div>
                        <a class="d-flex py-3" href="mailto:biuro@zonti.pl">
                            <div class="my-auto">
                                <i class="mdi mdi-email my-auto" style="font-size: 1.5em;"></i>
                            </div>
                            <div class="my-auto pl-2">biuro@zonti.pl</div>
                        </a>
                        <a class="d-flex" href="tel:+48609169500">
                            <div class="my-auto">
                                <i class="mdi mdi-phone my-auto" style="font-size: 1.5em;"></i>
                            </div>
                            <div class="my-auto pl-2">+48 609 169 500</div>
                        </a>
                    </div>
                </b-col>

                <b-col sm="6" lg="3" class="d-flex justify-content-center justify-content-sm-start">
                    <div class="zonti-element"></div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>
    .footer-container {
        background-color: #083737;
        position: relative;
        z-index: 100;
        margin-top: -2px;
    }
    h4, div {
        color: #FFF;
    }
    a {
        text-decoration: none;
        color: unset;
    }
    .zonti-element {
        opacity: 0.4;
        background-image: url('https://zonti-bucket.s3.amazonaws.com/assets/ZONTI-wizytowki2021-ACha.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        height: 200px;
        width: 200px;
    }

    @media only screen and (max-width: 576px) {
        h5 {
            text-align: center;
        }
    }
</style>