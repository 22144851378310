<template>
  <div id="app">
    <navbar class="d-none d-md-flex"></navbar>
    <navbar-mobile class="d-block d-md-none"></navbar-mobile>
    <router-view/>
    <Footer></Footer>
  </div>
</template>

<script>
  import Navbar from "./components/Navbar/Navbar";
  import Footer from "./components/Footer/Footer";
  import NavbarMobile from "./components/Navbar/NavbarMobile";
  export default {
    components: {NavbarMobile, Footer, Navbar}
  }
</script>

<style>
  @font-face {
    font-family: 'Montserrat';
    src: url('./assets/Fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  }

  .zonti-green {
    color: #034D4D!important;
  }
  .zonti-green-bg {
    background-color: #034D4D!important;
  }

  .logo {
    background-image: url('https://zonti-bucket.s3.amazonaws.com/assets/zonti_icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 40px;
    height: 40px;
  }

  .logo-full {
    background-image: url('https://zonti-bucket.s3.amazonaws.com/assets/ZONTI-wizytowki2021-ACha.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 60px;
    height: 60px;
  }

  .logo-full-zonti-clever-thinking {
    background-image: url('https://zonti-bucket.s3.amazonaws.com/assets/Zonti_Clever_Thinking.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 100px;
    height: 60px;
  }

  .logo-full-zonti-logotext {
    background-image: url('https://zonti-bucket.s3.amazonaws.com/assets/Zonti_logotext.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 100px;
    height: 60px;
  }

  #app {
    font-family: Montserrat;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #nav {
    padding: 30px;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }
</style>