<template>
    <div>
        <b-navbar
                class="px-5 zonti-green-bg"
                type="light"
                toggleable="xs"
        >

            <div class="d-flex justify-content-between" style="width: 100%;">
                <div class="d-flex my-auto" @click="$router.push({name: 'Home'})">
                    <div class="logo my-auto mr-2"></div>
                    <div class="logo-full-zonti-clever-thinking"></div>
                    <!--                    <h6 class="my-auto pl-3" style="color: #FFF;">{{ $t('navbar.home')}}</h6>-->
                </div>

                <div class="my-auto">
                    <b-button v-b-toggle.sidebar-main variant="'none'">
                        <i class="mdi mdi-menu my-auto" style="font-size: 1.5em; color: #FFF;"></i>
                    </b-button>
                </div>
            </div>

            <b-sidebar id="sidebar-main" shadow backdrop>
                <template v-slot:title>
                    <div class="d-flex my-auto" @click="$router.push({name: 'Home'})">
                        <div class="logo my-auto mr-2"></div>
                        <div class="logo-full-zonti-clever-thinking"></div>
                        <!--                        <h6 class="my-auto pl-3" style="color: #000;">{{ $t('navbar.home')}}</h6>-->
                    </div>
                </template>
                <div class="p-5 d-flex justify-content-around" style="flex-direction: column; height: 100%;">
                    <div>
                        <h4 @click="$router.push({path: '/'})">{{ $t('navbar.about')}}</h4>
                        <h4 class="py-4" @click="$router.push({name: 'Services'})">{{ $t('navbar.services')}}</h4>
                        <h4 class="pb-4" @click="$router.push({name: 'Documents'})">{{ $t('navbar.documents')}}</h4>
                        <h4 @click="$router.push({name: 'Contact'})">{{ $t('navbar.contact')}}</h4>
                        <!--                        <h4 class="pt-4" @click="$router.push({name: 'About'})">{{ $t('navbar.about')}}</h4>-->
                    </div>
                    <div>
                        <div class="d-flex">
                            <div class="my-auto">
                                <i class="mdi mdi-domain my-auto" style="font-size: 1.5em;"></i>
                            </div>
                            <div class="my-auto pl-2" style="white-space: nowrap">
                                <div>{{ $t('contact_info.street')}}</div>
                                <div>{{ $t('contact_info.city_zip')}}</div>
                            </div>
                        </div>
                        <a class="d-flex py-3" href="mailto:biuro@zonti.pl">
                            <div class="my-auto">
                                <i class="mdi mdi-email my-auto" style="font-size: 1.5em;"></i>
                            </div>
                            <div class="my-auto pl-2">{{ $t('contact_info.email')}}</div>
                        </a>
                        <a class="d-flex" href="tel:+48609169500">
                            <div class="my-auto">
                                <i class="mdi mdi-phone my-auto" style="font-size: 1.5em;"></i>
                            </div>
                            <div class="my-auto pl-2">{{ $t('contact_info.phone')}}</div>
                        </a>
                        <b-dropdown variant="none" class="pt-5" style="width: 50px;">
                            <template slot="button-content"><em style="font-weight: 600; text-transform: uppercase;">{{ $i18n.locale }}</em></template>
                            <b-dropdown-item @click="changeLanguageTo('en')" class="nav-dropdown-item">EN</b-dropdown-item>
                            <b-dropdown-item @click="changeLanguageTo('pl')" class="nav-dropdown-item">PL</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </b-sidebar>

        </b-navbar>
    </div>
</template>

<script>
    export default {
        name: "NavbarMobile",
        methods: {
            changeLanguageTo(value) {
                this.$i18n.locale = value;
            }
        }
    }
</script>

<style scoped>
    a {
        text-decoration: none;
        color: unset;
    }
</style>