<template>
    <div class="services-background" style="position: relative;">
        <div class="green-skew"></div>
        <b-container fluid class="inner-container">
            <b-row class="pb-5">
                <b-col sm="8" md="7" lg="5">
                    <h1 class="m-auto text-left">{{ $t('offer.offer_header')}}</h1>
                    <h5 class="m-auto py-5 pr-5">{{ $t('offer.offer_introduction')}}</h5>
                    <h4 class="m-auto pb-3 pr-5">{{ $t('offer.offer_list_header')}}</h4>
                    <ul class="p-0 pr-5 pr-sm-0 pl-sm-4" style="color: #FFF; line-height: 1.75;">
                        <li>{{ $t('offer.offer_item_1')}}</li>
                        <li>{{ $t('offer.offer_item_2')}}</li>
                        <li>{{ $t('offer.offer_item_3')}}</li>
                        <li>{{ $t('offer.offer_item_4')}}</li>
                    </ul>
                </b-col>
            </b-row>
        </b-container>
        <div class="zonti-element"></div>
    </div>
</template>

<script>
    export default {
        name: "Services"
    }
</script>

<style scoped>
    .services-background {
        background-image: url('https://zonti-bucket.s3.amazonaws.com/assets/images/compressed_images/DSC01082-min.JPG');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        min-height: 90vh;
        height: 100%;
        width: 100%;
        position: relative;
    }
    /*.green-skew {*/
    /*    background-color: #034D4D;*/
    /*    opacity: 0.96;*/
    /*    min-height: 90vh;*/
    /*    height: 100%;*/
    /*    width: 100%;*/
    /*    -webkit-clip-path: polygon(0 0, 70% 0, 45% 100%, 0% 100%);*/
    /*    clip-path: polygon(0 0, 70% 0, 45% 100%, 0% 100%);*/
    /*    backdrop-filter: blur(4px);*/
    /*    position: absolute;*/
    /*    top: 0;*/
    /*    left: 0;*/
    /*}*/
    .green-skew {
        background-color: #034D4D;
        opacity: 0.96;
        min-height: 90vh;
        height: 100%;
        width: 100%;
        -webkit-clip-path: polygon(0 0, 60% 0, 45% 100%, 0% 100%);
        clip-path: polygon(0 0, 60% 0, 45% 100%, 0% 100%);
        backdrop-filter: blur(4px);
        position: absolute;
        top: 0;
        left: 0;
    }
    h1, h4, h5, h6, p {
        color: #FFF;
        position: relative;
        z-index: 100;
        line-height: 1.5;
    }
    h1, h4 {
        font-weight: 700;
    }
    .zonti-element {
        opacity: 0.4;
        position: absolute;
        z-index: 100;
        bottom: 0;
        left: 0;
        background-image: url('https://zonti-bucket.s3.amazonaws.com/assets/zonti_icon.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        height: 200px;
        width: 200px;
        overflow: hidden;
    }
    .inner-container {
        padding-top: 150px;
        padding-left: 100px;
        margin-top: -6px;
    }

    @media only screen and (max-width: 1440px) {
        .green-skew {
            -webkit-clip-path: polygon(0 0, 70% 0, 40% 100%, 0% 100%);
            clip-path: polygon(0 0, 70% 0, 40% 100%, 0% 100%);
        }
    }

    @media only screen and (max-width: 1280px) {
        .green-skew {
            -webkit-clip-path: polygon(0 0, 70% 0, 45% 100%, 0% 100%);
            clip-path: polygon(0 0, 70% 0, 45% 100%, 0% 100%);
        }
    }

    @media only screen and (max-width: 991px) {
        .green-skew {
            -webkit-clip-path: polygon(0 0, 85% 0, 55% 100%, 0% 100%);
            clip-path: polygon(0 0, 85% 0, 55% 100%, 0% 100%);
        }
        .inner-container {
            padding-top: 100px;
            padding-left: 50px;
            margin-top: -1px;
        }
    }

    @media only screen and (max-width: 768px) {
        .green-skew {
            -webkit-clip-path: polygon(0 0, 100% 0, 65% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
        }
        .inner-container {
            margin-top: -2px;
        }
    }

    @media only screen and (max-width: 576px) {
        .green-skew {
            -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
        }
        h1 {
            text-align: center;
        }
        h5 {
            font-size: 1em;
        }
    }
</style>