<template>
    <b-navbar class="zonti-green-bg" style="padding-bottom: 1px;">
        <b-navbar-brand @click="$router.push({name: 'Home'})" class="navbar-margin" style="cursor: pointer;">
            <div class="d-flex">
                <div class="logo my-auto mr-2"></div>
                <div class="logo-full-zonti-clever-thinking"></div>
                <!--                <h6 class="my-auto pl-3" style="color: #FFF;">{{ $t('navbar.home')}}</h6>-->
            </div>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item @click="goToAbout">{{ $t('navbar.about')}}</b-nav-item>
                <b-nav-item @click="goToServices">{{ $t('navbar.services')}}</b-nav-item>
                <b-nav-item @click="goToDocuments">{{ $t('navbar.documents')}}</b-nav-item>
                <b-nav-item @click="goToContact">{{ $t('navbar.contact')}}</b-nav-item>
                <!--                <b-nav-item @click="goToAbout">{{ $t('navbar.about')}}</b-nav-item>-->

                <b-nav-item-dropdown right>
                    <template slot="button-content"><em style="font-weight: 600; text-transform: uppercase;">{{ $i18n.locale }}</em></template>
                    <b-dropdown-item @click="changeLanguageTo('en')" class="nav-dropdown-item">EN</b-dropdown-item>
                    <b-dropdown-item @click="changeLanguageTo('pl')" class="nav-dropdown-item">PL</b-dropdown-item>
                </b-nav-item-dropdown>

            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
    export default {
        name: "Navbar",
        methods: {
            goToContact() {
                this.$router.push({name: 'Contact'});
            },
            goToDocuments() {
                this.$router.push({name: 'Documents'});
            },
            goToServices() {
                this.$router.push({name: 'Services'});
            },
            goToAbout() {
                // this.$router.push({name: 'About'});
                this.$router.push({path: '/'});
            },
            changeLanguageTo(value) {
                this.$i18n.locale = value;
            }
        }
    }
</script>

<style scoped>
    .navbar {
        margin-bottom: -1px;
    }

    .navbar-nav .nav-item .nav-link {
        color: #FFF !important;
    }

    .navbar-nav >>> .dropdown-toggle em {
        color: #FFF;
        font-weight: 400!important;
    }

    .navbar-nav >>> .dropdown-toggle::after {
        color: #FFF;
    }

    .zonti-brand {
        background-image: url('https://zonti-bucket.s3.amazonaws.com/assets/ZONTI-wizytowki2021-ACha.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        width: 60px;
        height: 60px;
    }

    .navbar-margin {
        margin-left: 4em;
    }

    @media only screen and (max-width: 991px) and (min-width: 768px) {
        .navbar-margin {
            margin-left: 2em;
        }
    }
</style>