<template>
  <div class="home">
    <landing></landing>
  </div>
</template>

<script>
  // @ is an alias to /src
  import Landing from "@/components/Landing/Landing";

  export default {
    name: 'Home',
    components: {Landing}
  }
</script>
